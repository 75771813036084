import React from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  LogoutOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Menu, Button } from 'antd';
import { logout } from '../../services/Admin';
import { APP } from '../../services/Constants';
import './index.less';

function AppBar() {
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const onClick = () => {
    logout();
    localStorage.removeItem('sessionId');
    queryClient.clear();
    history.push('/auth');
  };

  return (
    <div id="AppBar">
      <img
        src="/logo_no_background.png"
        alt="buddies logo"
        width={45}
      />
      <Menu
        mode="horizontal"
        selectedKeys={[location.pathname.split('/')[1]]}
        style={{ minWidth: '770px' }}
      >
        {Object.entries(APP)
          .map(([key, {
            name,
            emoji,
          }]) => (
            <Menu.Item key={key}>
              <a href={`#/${key}`}>{`${emoji} ${name}`}</a>
            </Menu.Item>
          ))}
        <Menu.Item style={{ background: 'rgba(0,0,0,0.03)' }} key="stats">
          <a href="#/stats">📈 Stats</a>
        </Menu.Item>
      </Menu>
      <div className="Fill" />
      <Button
        icon={<LogoutOutlined />}
        onClick={onClick}
      >
        Log out
      </Button>
    </div>
  );
}

export default AppBar;
